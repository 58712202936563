import { render, staticRenderFns } from "./NotificationsWidget.vue?vue&type=template&id=5fbeb976&scoped=true&"
import script from "./NotificationsWidget.vue?vue&type=script&lang=ts&"
export * from "./NotificationsWidget.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationsWidget.vue?vue&type=style&index=0&id=5fbeb976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbeb976",
  null
  
)

/* custom blocks */
import block0 from "./NotificationsWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotificationsListItem: require('/codebuild/output/src801768262/src/frontend/components/notifications/NotificationsListItem.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VList,VMenu,VRow})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
